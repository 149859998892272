* {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    width: 100%;
    height: 100dvh;
    font-family: "Montserrat";
    font-style: normal;
    font-size: 20px;
    margin: 0 auto;
    background-color: #eaeaea;
    box-sizing: border-box;
}

h2 {
    margin: 0;
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0 0 0 1.5em;
}

li {
    margin: 1.5em 0;
    padding: 0;
}

b {
    /* used for event dates/times */
    margin-right: 3px;
}
.swiper-pagination-bullet {
    background-color: aliceblue;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: white;
}
